<template>
    <div class="flex flex-col w-full h-screen">
        <div class="flex flex-col w-full mt-6 mb-2 sm:px-4">
            <slot name="header">
                <Header
                    @onAdd="() => toggleSlideover(true)"
                    :title="listHeader.title"
                    :btnLabel="listHeader.btnText"
                    :count="get(items, 'length')"
                    :searchbox="listHeader.searchbox"
                    :filter="listHeader.filter"
                    :sort="listHeader.sort"
                    :isShowSearchBox="listHeader.showSearchbox"
                />
            </slot>
        </div>
        <div class="flex flex-col w-full h-screen px-4 sm:px-8 space-y-2">
            <template v-if="!isLoading && (!items || items.length === 0)">
                <EmptyState
                    class="bg-white"
                    isFull
                    :icon="emptyViewBinding.icon"
                    :title="emptyViewBinding.title"
                    :description="emptyViewBinding.description"
                    :btnText="emptyViewBinding.btnText"
                    :action="() => slideOver(true)"
                />
                <!-- <slot name="add"> </slot> -->
            </template>
            <template v-else>
                <div
                    class="flex flex-col border border-gray-300"
                    v-for="item in getData(items)"
                    :key="item"
                >
                    <Items v-bind="item" @onClick="onClickItem(item)" />
                </div>
                <CardFooterWithPageButtons
                    class="px-0 bg-opacity-0"
                    :onChangePerPage="(count) => perPageChanged(count)"
                    :totalRecords="items?.length"
                    :perPage="perPage"
                    :currentPage="currentPage"
                    :onChangePage="(page) => pageChanged(page)"
                />
            </template>
        </div>
        <slot name="add"> </slot>
    </div>
</template>

<script>
import EmptyState from "../EmptyState/Simple/Simple";
import Header from "../../molecules/ListViewHeader/WithFilter/ListViewHeader.vue";
import Items from "../../molecules/CardListItem/CardWithBadge/CardWithBadge.vue";
import Icon from "../../atoms/Icons/Icons.vue";
import Text from "../../atoms/Text/Text.vue";
import Simple from "../Tables/Simple/Simple.vue";
import CardFooterWithPageButtons from "../Paginations/CardFooterWithPageButtons/CardFooterWithPageButtons.vue";
import { c } from "../../constants.js";
import { get } from "lodash";

/**
 * - Use it to show a LinkedRecordList
 */
export default {
    components: {
        Header,
        Items,
        Text,
        Simple,
        Icon,
        CardFooterWithPageButtons,
        EmptyState,
    },
    props: {
        /**
         * Use it for list view items
         */
        items: {
            type: Array,
            default: () => [],
        },
        listHeader: {
            type: Object,
            default: () => {},
        },
        recordPerPage: {
            type: Number,
            default: 10,
        },
        /**
         * total records in table
         */
        pageTotalRecords: {
            type: Number,
            default: 0,
        },
        /**
         * current page of record table
         */
        recordActivePage: {
            type: Number,
            default: 1,
        },
        /**
         * action to get page data
         */
        getPageData: {
            type: Function,
            defaullt: () => {},
        },
        /**
         * loading status
         */
        isLoading: {
            type: Boolean,
            default: false,
        },
        onClickItem: {
            type: Function,
            default: () => {},
        },
        toggleSlideover: {
            type: Function,
            default: () => {},
        },
        viewName: {
            type: String,
            default: "",
        },
        emptyViewBinding: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            c,
            get,
            perPage: 10,
            currentPage: 1,
        };
    },
    methods: {
        getData(items) {
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            );
        },
        async perPageChanged(count) {
            this.perPage = count;
        },
        async pageChanged(page) {
            this.currentPage = page;
        },
        slideOver() {
            console.log("slideOver");
            this.toggleSlideover(true);
        },
    },
};
</script>
